type MessageTemplates = {
  and: Intl.Message
  base: Intl.Message
  paid?: Intl.Message
  withRemainingShipments?: Intl.Message
}

type MessageAddons = {
  candle: Intl.Message
  case: Intl.Message
  drift: Intl.Message
}

type Messages = {
  addons: MessageAddons
  templates: MessageTemplates
}

type GetSubscriptionAddonsReminderMessageProps = {
  formatMessage: (message: Intl.Message) => string
  hasCandleSubscription: boolean
  hasCaseSubscription: boolean
  hasDriftSubscription: boolean
  hasPaidSubscriptions?: boolean
  hasRemainingShipments?: boolean
  messages: Messages
}

const getSubscriptionAddonsReminderMessage = ({
  formatMessage,
  hasCandleSubscription,
  hasCaseSubscription,
  hasDriftSubscription,
  hasPaidSubscriptions,
  hasRemainingShipments,
  messages,
}: GetSubscriptionAddonsReminderMessageProps) => {
  const activatedAddons = []

  if (hasCandleSubscription) {
    activatedAddons.push(formatMessage(messages.addons.candle))
  }

  if (hasCaseSubscription) {
    activatedAddons.push(formatMessage(messages.addons.case))
  }

  if (hasDriftSubscription) {
    activatedAddons.push(formatMessage(messages.addons.drift))
  }

  const count = activatedAddons.length

  if (count === 0) {
    return null
  }

  const [ tail, ...head ] = activatedAddons.reverse()
  const commaSeparatedParts = head.reverse().join(', ')
  const subscriptionAddons = commaSeparatedParts.length > 0
    ? `${commaSeparatedParts} ${formatMessage(messages.templates.and)} ${tail}`
    : tail

  const baseReminderPart = formatMessage({ ...messages.templates.base, values: { count, subscriptionAddons } })

  const paidTemplate = hasPaidSubscriptions && messages.templates.paid
  const withRemainingShipmentsTemplate = hasRemainingShipments && messages.templates.withRemainingShipments
  const extraTemplate = withRemainingShipmentsTemplate || paidTemplate

  const extraReminderPart = extraTemplate && formatMessage({ ...extraTemplate, values: { subscriptionAddons } })

  return extraReminderPart ? `${baseReminderPart} ${extraReminderPart}` : baseReminderPart
}

export default getSubscriptionAddonsReminderMessage
