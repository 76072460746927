import { useCallback, useMemo, useState } from 'react'
import { useApolloClient, useMutation, useQuery, type DocumentNode } from 'apollo-client'
import { CancelledPromiseError, constants, GraphQLError, UserError, ValidationError } from 'helpers'
import { useFt } from 'hooks'
import links from 'links'
import { openModal } from 'modals'

import type { UpchargeStatus } from 'typings/graphql'

import candleSubscriptionQuery, { type CandleSubscriptionVariables } from './graph/candleSubscription.graphql'
import caseSubscriptionQuery, { type CaseSubscriptionVariables } from './graph/caseSubscription.graphql'
import driftSubscriptionQuery, { type DriftSubscriptionVariables } from './graph/driftSubscription.graphql'
import subscriptionSettingsUpdateQuery, { type SubscriptionSettingsUpdateVariables } from './graph/subscriptionSettingsUpdate.graphql'

import messages from './messages'


type UseCaseSubscriptionInput = {
  skip?: boolean
  withProduct?: CaseSubscriptionVariables['withProduct']
}

export const useCaseSubscription = ({ withProduct = false, skip = false }: UseCaseSubscriptionInput = {}) => {
  const { data, isFetching, error } = useQuery(caseSubscriptionQuery, {
    skip,
    variables: {
      withProduct,
    },
    fetchPolicy: 'cache-first',
  })

  return {
    caseSubscription: data?.currentUser?.data?.caseSubscription,
    isFetching,
    error,
  }
}

type UseCandleSubscriptionInput = {
  skip?: boolean
  withProduct?: CandleSubscriptionVariables['withProduct']
}

export const useCandleSubscription = ({ withProduct = false, skip = false }: UseCandleSubscriptionInput = {}) => {
  const { data, isFetching, error } = useQuery(candleSubscriptionQuery, {
    skip,
    variables: {
      withProduct,
    },
    fetchPolicy: 'cache-first',
  })

  const candleSubscription = useMemo(() => {
    const rawData = data?.currentUser?.data?.candleSubscription

    if (!rawData) {
      return null
    }

    return {
      ...rawData,
      price: rawData.price || 2500, // ATTN price on backend is taken from current candle of the month, this fallback for corner-case if CotM isn't defined yet
    }
  }, [ data ])

  return {
    candleSubscription,
    isFetching,
    error,
  }
}

type UseDriftSubscriptionInput = {
  skip?: boolean
  withProduct?: DriftSubscriptionVariables['withProduct']
}

export const useDriftSubscription = ({ skip = false, withProduct = false }: UseDriftSubscriptionInput = {}) => {
  const isDriftSubscriptionEnabled = useFt(constants.features.driftSubscription)
  const { data, isFetching, error } = useQuery(driftSubscriptionQuery, {
    skip: skip || !isDriftSubscriptionEnabled,
    variables: {
      withProduct,
    },
    fetchPolicy: 'cache-first',
  })

  return {
    driftSubscription: data?.currentUser?.data?.driftSubscription,
    isFetching,
    error,
  }
}

const openTryLaterModal = () => {
  const closeModal = openModal('commonModal', {
    'data-testid': 'subscriptionAddonTryLaterModal',
    title: messages.errors.tryLater.title,
    text: messages.errors.tryLater.text,
    primaryButton: {
      title: messages.errors.tryLater.primaryButton,
      onClick: () => {
        closeModal()
      },
    },
  })
}

type SubscriptionAddonType = 'candle' | 'case' | 'drift'

type UseSubscriptionSettingsUpdateProps = {
  type: SubscriptionAddonType
  withProduct?: boolean
  toEnable: boolean
  metadata: SubscriptionSettingsUpdateVariables['input']['metadata']
}

export const useSubscriptionSettingsUpdate = () => {
  const [ mutate, { isFetching } ] = useMutation(subscriptionSettingsUpdateQuery)

  const submit = useCallback(async (props: UseSubscriptionSettingsUpdateProps) => {
    const { withProduct = false, type, toEnable, metadata } = props

    const isCandle = type === 'candle'
    const isCase = type === 'case'
    const isDrift = type === 'drift'

    const result = await mutate({
      variables: {
        input: {
          [type]: toEnable ? 'ENABLED' : 'DISABLED',
          metadata,
        },
        isCandle,
        isCase,
        isDrift,
        withProduct,
      },
      context: {
        timeout: 0, // disable timeout
      },
    })

    if (result.errors) {
      throw new GraphQLError(result.errors)
    }

    const { data, error } = result.data.subscriptionSettingsUpdate

    if (error) {
      if (error.__typename === 'SubscriptionSettingsError') {
        const { subscriptionSettingsErrorCode, metadata } = error

        if (subscriptionSettingsErrorCode === 'SUBSCRIPTION_HAS_SAME_STATE') {
          return data
        }

        if (metadata) {
          const { needCardUpdate, needTryAgain, needTryLater } = metadata

          if (needTryLater) {
            openTryLaterModal()

            throw new CancelledPromiseError()
          }

          if (needCardUpdate || needTryAgain) {
            const closeModal = openModal('commonModal', {
              'data-testid': 'subscriptionAddonTryAgainModal',
              title: messages.errors.tryAgain.title,
              text: messages.errors.tryAgain.text,
              primaryButton: {
                title: messages.errors.tryAgain.primaryButton,
                to: `${links.cardUpdate}?subscriptionAddon=${type}`,
              },
              secondaryButton: {
                title: messages.errors.tryAgain.secondaryButton,
                onClick: () => {
                  closeModal()
                },
              },
            })

            throw new CancelledPromiseError()
          }
        }
      }

      if (error.__typename === 'ValidationError') {
        throw new ValidationError(error)
      }

      throw new UserError(error)
    }

    return data
  }, [ mutate ])

  return [
    submit,
    { isFetching },
  ] as const
}

const SUBSCRIPTION_ADDON_FIELD: Record<SubscriptionAddonType, string> = {
  'candle': 'candleSubscription',
  'case': 'caseSubscription',
  'drift': 'driftSubscription',
}

const SUBSCRIPTION_ADDON_QUERY: Record<SubscriptionAddonType, DocumentNode> = {
  'candle': candleSubscriptionQuery,
  'case': caseSubscriptionQuery,
  'drift': driftSubscriptionQuery,
}

const POLL_TIMEOUT = 60 * 1000
const POLL_INTERVAL = 2500
const POLL_RETRIES_COUNT = Math.ceil(POLL_TIMEOUT / POLL_INTERVAL)

export const useSubscriptionSettingsUpdateWaitForPurchase = () => {
  const apolloClient = useApolloClient()

  return useCallback((type: SubscriptionAddonType) => {
    const addonField = SUBSCRIPTION_ADDON_FIELD[type]
    const addonQuery = SUBSCRIPTION_ADDON_QUERY[type]

    return new Promise<void>((resolve, reject) => {
      let retriesCount = 0

      const observer = async () => {
        retriesCount += 1

        if (retriesCount > POLL_RETRIES_COUNT) {
          openTryLaterModal()

          return reject(new CancelledPromiseError())
        }

        const { data } = await apolloClient.query({
          query: addonQuery,
          variables: { withProduct: false },
          fetchPolicy: 'network-only',
        })

        const addonData = data.currentUser?.data?.[addonField]
        const isAddonSelected = addonData?.isSelected
        const upchargeStatus: UpchargeStatus = addonData?.upcharge?.status

        if (upchargeStatus === 'CHARGED') {
          return resolve()
        }

        if ((!isAddonSelected && upchargeStatus === 'NEW' ) || upchargeStatus === 'IN_PROGRESS' || !upchargeStatus) {
          return setTimeout(observer, POLL_INTERVAL)
        }

        // TODO: Throw error with better error message
        return reject(new UserError(null, 'Something went wrong'))
      }

      setTimeout(observer, POLL_INTERVAL)
    })
  }, [ apolloClient ])
}

export const useSubscriptionSettingsUpdateWithWaiter = () => {
  const [ updateMutation, { isFetching } ] = useSubscriptionSettingsUpdate()
  const waitForPurchase = useSubscriptionSettingsUpdateWaitForPurchase()

  const [ isWaiting, setIsWaiting ] = useState(false)

  const submit = useCallback(async (input: UseSubscriptionSettingsUpdateProps) => {
    const { toEnable, type } = input

    setIsWaiting(true)

    try {
      const data = await updateMutation(input)

      if (toEnable) {
        const addonField = SUBSCRIPTION_ADDON_FIELD[type]
        const addonData = data?.[addonField]

        if (!addonData?.isSelected) {
          const upchargeStatus: UpchargeStatus = addonData?.upcharge?.status

          // subscription is not activated and payment status is unknown
          if (upchargeStatus === 'NEW') {
            throw new UserError({ subscriptionSettingsErrorCode: 'PURCHASE_IN_PROGRESS' })
          }

          // will be changed in the future (card failed is not implemented yet on the be)
          if (upchargeStatus === 'CANCELLED' || upchargeStatus === 'FAILED' || upchargeStatus === 'INVALID_PAYMENT_METHOD') {
            throw new UserError({ upchargeStatus }, 'Something went wrong')
          }
        }
      }

      return data
    }
    catch (error) {
      const isPostponedPurchase = (
        // if it's a gateway timeout error, then we start waiting for the purchase
        error?.networkError?.statusCode === 504
        // error code from the error. We use cause because all payment errors are wrapped in UserError
        || error?.cause?.subscriptionSettingsErrorCode === 'PURCHASE_IN_PROGRESS'
      )

      if (toEnable && isPostponedPurchase) {
        return await waitForPurchase(type)
      }

      throw error
    }
    finally {
      setIsWaiting(false)
    }
  }, [ updateMutation, waitForPurchase ])

  return [ submit, { isFetching: isFetching || isWaiting } ] as const
}
